<template>
  <div>
    <WorkPlace />
    <Education />
  </div>
</template>

<script>
import WorkPlace from "./workplace";
import Education from "./education";
//import Profession from "./profession";
export default {
  components: {
    WorkPlace,
    Education
  }
};
</script>

<style></style>
